@import './src/constants/styling.scss';

.passwordUpdate {
  z-index: 2;
  height: 100%;
  display: flex;
  padding-top: 15px;
  background: $submenuBg;
  border-radius: $roundedDivs;
  color: $textOnDark;
  box-shadow: 15px 15px 25px 25px $shadow;

  hr {
    margin-top: 5px;
    width: 100%;
    border-color: $textOnDark;
  }

  .row {
    width: 100%;
    margin-left: 0px;
  }

  form {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;

    input {
      &[name=newPasswordConfirm] {
        margin-bottom: 10px;
      }

      &[type=passwordUnmasked] {
        font-style: italic;
      }
    }

    button {
      width: 100%;
    }
  }

  .passwordMasking {
    position: absolute;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1.1rem;
    align-items: flex-end;
    font-size: 1.063rem;

    svg {
      color: #343a40;
      display: block;
      pointer-events: visible;
      height: calc(100%);
    }
  }
}
