@import './src/constants/styling.scss';

.AccountPage {
  height: 100%;
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  .profileContent {
    margin-top: 15px;
    height: calc(100% - 30px);
    transition: $slideIn;

    &.mobileMode {
      margin: 0px;
      padding: 15px;
      height: 100%;
    }

    .shader {
      position: absolute;
      left: 0;
      z-index: 1;
      width: 30px;
      height: 180px;
      background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0));

      &.inverse {
        // Move to right side, minus margin and width of self
        width: 15px;
        left: calc(100% - 20px - 15px);
        background-image: linear-gradient(to left, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 0));
      }
    }

    .rankings {
      width: calc(100% + 10px);
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      margin: 0px -15px;
      padding: 0px 15px;

      .rankingCard {
        height: 175px;
        width: 140px;
        flex: none;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0px;
        margin-right: 20px;

        &:last-child {
          margin-right: -10px;
        }

        &.gold {
          background-image: linear-gradient(to bottom right, hsl(42, 65%, 41%), hsl(56, 87%, 75%) 35%, hsl(44, 61%, 55%) 75%, hsl(51, 78%, 66%) 85%, hsl(42, 65%, 41%))
        }

        &.silver {
          background-image: linear-gradient(to bottom right, hsl(60, 1%, 48%), hsl(80, 1%, 88%) 35%, hsl(64, 1%, 59%) 75%, hsl(73, 1%, 77%) 85%, hsl(60, 1%, 48%))
        }

        &.bronze {
          background-image: linear-gradient(to bottom right, hsl(27, 73%, 37%), hsl(27, 70%, 51%) 35%, hsl(27, 72%, 34%) 75%, hsl(29, 73%, 57%) 85%, hsl(27, 73%, 37%))
        }

        object {
          height: 140px;
          width: 100%;
          padding: 0px 17.5px;
          align-self: center;
        }

        p {
          text-transform: capitalize;
          font-weight: bold;
          margin: 7px 0px;

          &.noRank {
            text-transform: none;
            font-weight: lighter;
            font-style: italic;
          }
        }
      }
    }

    .profileTools {
      padding: 0px; 
      margin: 10px 0px;

      &.pad { margin-top: 0px; }

      button {
        min-width: 200px;
        margin-right: 20px;
      }
    }
  }

  .reportHeader {
    margin-right: 10px;
  }

  .profileContentInner {
    //Full height - margins - header
    height: calc(100% - 5px - 33px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    min-height: 0px;

    &.mobileMode {
      //Full height - header
      height: calc(100%  - 45px);
      padding-bottom: 5px;
    }
  }
}