@import './src/constants/styling.scss';

.ConfirmAlert {
  .modal-content {
    border-radius: $roundedCards;
  
    .modal-header {
      border-radius: $roundedCardHeader;
      flex-direction: column;

      &.deleteHeader {
        background-color: #ff5252;
        color: #fff;

        .modal-title {
          font-weight: normal;
        }

        .secondTitle {
          color: #000;
          font-weight: bold;
        }
      }

      p {
        margin: 0px;
      }
    }

    .buttonBar{
      margin-top: 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 10px;
      
      .col-3 {
        padding: 0px 5px;
      
        button {
          font-weight: bold;
          font-size: 15px;
        }
      }
    }
  }
}