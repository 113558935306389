@import './src/constants/styling.scss';

.resultPage {
  height: 100%;
  display: flex;
  background: $submenuBg;
  border-radius: $roundedDivs;
  color: $textOnDark;
  box-shadow: 15px 15px 25px 25px $shadow;

  .acceptedPlayers {
    width: 100%;
    padding-bottom: 10px;

    .teamHeaders {
      text-align: center;

      p {
        margin: 5px;

        &.teamOne { color: $teamOne; }
        &.teamTwo { color: $teamTwo; }
      }
    }

    > div.row:not(.teamHeaders) > div {
      height: 52px;
      width: 100%;
      padding: 0px;
      margin: 5px 0px;

      &.teamSwapper {
        display: flex;
        font-size: 20px;

        svg {
          width: 100%;
          color: $textOnDark;
          opacity: 0.3;
          align-self: center;
          text-align: center;
          cursor: pointer;
        }
      }

      .media {
        border-radius: calc(1.078em / 2);
        background-image: $browserNavbarBg;
        color: black;
        cursor: pointer;

        &.teamOne { border: 3px solid $teamOne; }
        &.teamTwo { border: 3px solid $teamTwo; }
        
        .media-body {
          padding: 2px;
          max-width: calc(100% - 40px);

          p {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0px;
          }
        }
      }
    }

    p.error {
      margin-top: 15px;
      margin-bottom: 0px;
    }
  }

  hr {
    border-color: $textOnDark;
  }

  .container-fluid {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    margin: 15px 0px;
  }

  .row, form {
    width: 100%;
    margin-left: 0px;
  }

  .form-row {
    .col {
      justify-content: center;
      display: flex;

      .form-check label {
        padding-left: 5px;
      }
    }
  }

  div.textarea {
    width: 100%;
    height: 82px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.1rem;
    font-size: 1.063rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #e9ecef;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;

    p {
      width: 100%;
      align-self: center;
      text-align: center;
      margin: 0px;
    }
  }

  form button,
  form input,
  form textarea,
  form label,
  button {
    width: 100%;
    margin-bottom: 10px;
  }

  .btn-group button {
    width: unset;
  }
}
