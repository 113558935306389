@import url(https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap);
html, body, #root,
#root > div.container-fluid,
#root > div.container-fluid > div#lastElementPreNavigation {
  height: 100%;
  overflow: hidden;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  font-family: 'Raleway';
  font-size: 14px;
  flex-direction: row;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ddaead), to(#d1b477));
  background-image: linear-gradient(to bottom, #ddaead, #d1b477); }
  html h1, html h2, html h3, html h4, html h5, body h1, body h2, body h3, body h4, body h5, #root h1, #root h2, #root h3, #root h4, #root h5,
  #root > div.container-fluid h1,
  #root > div.container-fluid h2,
  #root > div.container-fluid h3,
  #root > div.container-fluid h4,
  #root > div.container-fluid h5,
  #root > div.container-fluid > div#lastElementPreNavigation h1,
  #root > div.container-fluid > div#lastElementPreNavigation h2,
  #root > div.container-fluid > div#lastElementPreNavigation h3,
  #root > div.container-fluid > div#lastElementPreNavigation h4,
  #root > div.container-fluid > div#lastElementPreNavigation h5 {
    font-weight: normal; }
  html h1, body h1, #root h1,
  #root > div.container-fluid h1,
  #root > div.container-fluid > div#lastElementPreNavigation h1 {
    font-size: 24px; }
  html.whiteBackground, body.whiteBackground, #root.whiteBackground,
  #root > div.container-fluid.whiteBackground,
  #root > div.container-fluid > div#lastElementPreNavigation.whiteBackground {
    background-image: none;
    background-color: #fff; }
  html .content, body .content, #root .content,
  #root > div.container-fluid .content,
  #root > div.container-fluid > div#lastElementPreNavigation .content {
    padding: 0px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #fff; }
    @media (max-width: 767px) {
      html .content .browserSidebar, body .content .browserSidebar, #root .content .browserSidebar,
      #root > div.container-fluid .content .browserSidebar,
      #root > div.container-fluid > div#lastElementPreNavigation .content .browserSidebar {
        width: 100%;
        height: 100%;
        position: absolute !important;
        overflow: hidden;
        top: 0;
        left: 0;
        z-index: 10; } }
    html .content .mobileOverlay, body .content .mobileOverlay, #root .content .mobileOverlay,
    #root > div.container-fluid .content .mobileOverlay,
    #root > div.container-fluid > div#lastElementPreNavigation .content .mobileOverlay {
      width: 100%;
      height: calc(100% - calc(39.781px + 7px) - 7px - 1px);
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      z-index: 10;
      border-radius: 15px 0px 0px 15px; }
      html .content .mobileOverlay > .container-fluid, body .content .mobileOverlay > .container-fluid, #root .content .mobileOverlay > .container-fluid,
      #root > div.container-fluid .content .mobileOverlay > .container-fluid,
      #root > div.container-fluid > div#lastElementPreNavigation .content .mobileOverlay > .container-fluid {
        margin: 3px;
        padding: 12px; }
      html .content .mobileOverlay.iPhone:not(.w320h568):not(.w375h667):not(.w414h736), body .content .mobileOverlay.iPhone:not(.w320h568):not(.w375h667):not(.w414h736), #root .content .mobileOverlay.iPhone:not(.w320h568):not(.w375h667):not(.w414h736),
      #root > div.container-fluid .content .mobileOverlay.iPhone:not(.w320h568):not(.w375h667):not(.w414h736),
      #root > div.container-fluid > div#lastElementPreNavigation .content .mobileOverlay.iPhone:not(.w320h568):not(.w375h667):not(.w414h736) {
        height: calc(100% - calc(39.781px + 7px) - 44px - 1px); }
    html .content.mobileUI, body .content.mobileUI, #root .content.mobileUI,
    #root > div.container-fluid .content.mobileUI,
    #root > div.container-fluid > div#lastElementPreNavigation .content.mobileUI {
      height: calc(100% - calc(39.781px + 7px) - 7px); }
    html .content.iPhone:not(.w320h568):not(.w375h667):not(.w414h736), body .content.iPhone:not(.w320h568):not(.w375h667):not(.w414h736), #root .content.iPhone:not(.w320h568):not(.w375h667):not(.w414h736),
    #root > div.container-fluid .content.iPhone:not(.w320h568):not(.w375h667):not(.w414h736),
    #root > div.container-fluid > div#lastElementPreNavigation .content.iPhone:not(.w320h568):not(.w375h667):not(.w414h736) {
      height: calc(100% - calc(39.781px + 7px) - 44px); }
  html .navBrowser,
  html .contentCol, body .navBrowser,
  body .contentCol, #root .navBrowser,
  #root .contentCol,
  #root > div.container-fluid .navBrowser,
  #root > div.container-fluid .contentCol,
  #root > div.container-fluid > div#lastElementPreNavigation .navBrowser,
  #root > div.container-fluid > div#lastElementPreNavigation .contentCol {
    padding: 0px; }
  html .contentCol, body .contentCol, #root .contentCol,
  #root > div.container-fluid .contentCol,
  #root > div.container-fluid > div#lastElementPreNavigation .contentCol {
    background-color: #fff;
    box-shadow: 15px 15px 25px 25px rgba(1, 1, 1, 0.2);
    border-radius: 25px 0px 0px 25px; }
  html p.error, body p.error, #root p.error,
  #root > div.container-fluid p.error,
  #root > div.container-fluid > div#lastElementPreNavigation p.error {
    color: #ff0000; }
  html p.success, body p.success, #root p.success,
  #root > div.container-fluid p.success,
  #root > div.container-fluid > div#lastElementPreNavigation p.success {
    color: #17e217; }
  html p.desc, body p.desc, #root p.desc,
  #root > div.container-fluid p.desc,
  #root > div.container-fluid > div#lastElementPreNavigation p.desc {
    text-align: center;
    font-style: italic; }
  html button, body button, #root button,
  #root > div.container-fluid button,
  #root > div.container-fluid > div#lastElementPreNavigation button {
    font-weight: bold;
    font-size: 15px; }
  html .skeletonLoading, body .skeletonLoading, #root .skeletonLoading,
  #root > div.container-fluid .skeletonLoading,
  #root > div.container-fluid > div#lastElementPreNavigation .skeletonLoading {
    background-color: #ced4da;
    background-image: linear-gradient(280deg, #ced4da 20%, #eaeef1 50%, #ced4da 80%);
    -webkit-animation: skeletonShimmer 4s infinite linear;
            animation: skeletonShimmer 4s infinite linear;
    background-attachment: fixed;
    background-size: 200%;
    margin: 0; }
    html .skeletonLoading.line, body .skeletonLoading.line, #root .skeletonLoading.line,
    #root > div.container-fluid .skeletonLoading.line,
    #root > div.container-fluid > div#lastElementPreNavigation .skeletonLoading.line {
      display: inline-block;
      border-radius: 4px; }
    html .skeletonLoading.circle, body .skeletonLoading.circle, #root .skeletonLoading.circle,
    #root > div.container-fluid .skeletonLoading.circle,
    #root > div.container-fluid > div#lastElementPreNavigation .skeletonLoading.circle {
      display: inline-block;
      border-radius: 50%;
      margin-right: 7.5px; }
  html svg.skeletonLoading, body svg.skeletonLoading, #root svg.skeletonLoading,
  #root > div.container-fluid svg.skeletonLoading,
  #root > div.container-fluid > div#lastElementPreNavigation svg.skeletonLoading {
    background-image: none;
    background-color: transparent; }
  html .paginationTools, body .paginationTools, #root .paginationTools,
  #root > div.container-fluid .paginationTools,
  #root > div.container-fluid > div#lastElementPreNavigation .paginationTools {
    text-align: center; }
    html .paginationTools p, body .paginationTools p, #root .paginationTools p,
    #root > div.container-fluid .paginationTools p,
    #root > div.container-fluid > div#lastElementPreNavigation .paginationTools p {
      margin-bottom: 0; }
      html .paginationTools p.paginationLink, body .paginationTools p.paginationLink, #root .paginationTools p.paginationLink,
      #root > div.container-fluid .paginationTools p.paginationLink,
      #root > div.container-fluid > div#lastElementPreNavigation .paginationTools p.paginationLink {
        display: inline;
        text-shadow: -2px -2px white, -2px 2px white, 2px -2px white, 2px 2px white;
        padding-bottom: 1.5px; }
        html .paginationTools p.paginationLink:hover, body .paginationTools p.paginationLink:hover, #root .paginationTools p.paginationLink:hover,
        #root > div.container-fluid .paginationTools p.paginationLink:hover,
        #root > div.container-fluid > div#lastElementPreNavigation .paginationTools p.paginationLink:hover {
          background-size: 1px 1em;
          box-shadow: inset 0 -0.175em white, inset 0 -0.2em #000; }
  html p .line, body p .line, #root p .line,
  #root > div.container-fluid p .line,
  #root > div.container-fluid > div#lastElementPreNavigation p .line {
    height: 14px; }
  html p .circle, body p .circle, #root p .circle,
  #root > div.container-fluid p .circle,
  #root > div.container-fluid > div#lastElementPreNavigation p .circle {
    width: 14px;
    height: 14px; }
  html *, body *, #root *,
  #root > div.container-fluid *,
  #root > div.container-fluid > div#lastElementPreNavigation * {
    font-family: 'Raleway'; }

@-webkit-keyframes skeletonShimmer {
  to {
    background-position-x: -200%; } }

@keyframes skeletonShimmer {
  to {
    background-position-x: -200%; } }

.navBrowser {
  height: 100%;
  max-width: 250px; }
  .navBrowser #navigation {
    background-image: -webkit-gradient(linear, left top, right bottom, from(#e8a7e3), to(#d1b477));
    background-image: linear-gradient(to bottom right, #e8a7e3, #d1b477);
    height: 100%;
    flex-direction: column; }
    .navBrowser #navigation .navbar-brand {
      margin: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column; }
      .navBrowser #navigation .navbar-brand img {
        align-self: center; }
    .navBrowser #navigation .navbar-nav {
      flex-direction: column; }
      .navBrowser #navigation .navbar-nav a {
        display: block;
        font-size: 16px; }
        .navBrowser #navigation .navbar-nav a svg {
          padding-top: 8px; }
        .navBrowser #navigation .navbar-nav a.notActive {
          color: rgba(255, 255, 255, 0.6); }

.NavigationMobile {
  padding: 7px; }
  .NavigationMobile.iPhone:not(.w320h568):not(.w375h667):not(.w414h736) {
    padding-bottom: 44px; }
  .NavigationMobile .col-2 {
    text-align: center;
    padding: 0px; }
    .NavigationMobile .col-2 a {
      color: #333;
      white-space: nowrap; }
      .NavigationMobile .col-2 a svg {
        align-self: center; }
      .NavigationMobile .col-2 a p {
        margin: 0px;
        font-size: 8px;
        padding-bottom: 0px; }
        @media (max-width: 350px) {
          .NavigationMobile .col-2 a p {
            font-size: 7px;
            padding-bottom: 1px; } }
        @media (min-width: 450px) {
          .NavigationMobile .col-2 a p {
            font-size: 9px;
            margin-bottom: -1px; } }
        @media (min-width: 500px) {
          .NavigationMobile .col-2 a p {
            font-size: 10px;
            margin-bottom: -2px; } }
        @media (min-width: 700px) {
          .NavigationMobile .col-2 a p {
            font-size: 11px;
            margin-bottom: -3px; } }
      .NavigationMobile .col-2 a:visited {
        color: #333; }
      .NavigationMobile .col-2 a:hover, .NavigationMobile .col-2 a.active {
        color: #ddaead;
        text-decoration: none; }
      .NavigationMobile .col-2 a.notActive {
        color: #333; }
  @media (min-width: 768px) {
    .NavigationMobile .container {
      max-width: 800px; } }
  @media (min-width: 992px) {
    .NavigationMobile .container {
      max-width: 1000px; } }
  @media (min-width: 1200px) {
    .NavigationMobile .container {
      max-width: 1200px; } }

.LoginPage {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center; }
  .LoginPage .btn {
    width: 100%; }
    .LoginPage .btn.btn-facebook {
      background-image: linear-gradient(150deg, #3b5998, #2286c0, #0980c5, #1d78c2, #3b5998); }
  .LoginPage form button,
  .LoginPage form input,
  .LoginPage form label {
    margin-bottom: 10px; }
    .LoginPage form button[type=passwordUnmasked],
    .LoginPage form input[type=passwordUnmasked],
    .LoginPage form label[type=passwordUnmasked] {
      font-style: italic; }
  .LoginPage .passwordMasking {
    position: absolute;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1.1rem;
    align-items: flex-end;
    font-size: 1.063rem; }
    .LoginPage .passwordMasking svg {
      display: block;
      pointer-events: visible;
      height: calc(100%); }
  .LoginPage .loadingIndication {
    display: flex;
    flex-direction: column;
    align-items: center; }

.Report p {
  width: 100%;
  align-self: center;
  text-align: center;
  margin: 0px; }

.Report form select,
.Report form textarea {
  margin-bottom: 10px; }

.Report form button {
  font-weight: bold;
  font-size: 15px; }

.matchPage {
  height: 100%;
  display: flex;
  background: #271933;
  border-radius: 25px 0px 0px 25px;
  color: #eee;
  box-shadow: 15px 15px 25px 25px rgba(1, 1, 1, 0.2); }
  .matchPage .media {
    width: 100%; }
    .matchPage .media p {
      margin-bottom: 0px; }
  .matchPage .acceptedPlayers {
    width: 100%; }
    .matchPage .acceptedPlayers > div {
      padding: 0px;
      margin: 5px 0px; }
      .matchPage .acceptedPlayers > div .media p {
        text-overflow: ellipsis;
        margin: 0px; }
  .matchPage hr {
    border-color: #eee; }
  .matchPage .container-fluid {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    margin: 15px 0px; }
  .matchPage .buttonRow .col-6 {
    padding: 0px 5px 0px 0px; }
    .matchPage .buttonRow .col-6:last-child {
      padding: 0px 0px 0px 5px; }
  .matchPage .row, .matchPage form {
    width: 100%;
    margin-left: 0px; }
  .matchPage div.textarea {
    width: 100%;
    height: 82px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.1rem;
    font-size: 1.063rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #e9ecef;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem; }
    .matchPage div.textarea p {
      width: 100%;
      align-self: center;
      text-align: center;
      margin: 0px; }
  .matchPage form button,
  .matchPage form input,
  .matchPage form textarea,
  .matchPage form label,
  .matchPage button {
    width: 100%;
    margin-bottom: 10px; }

.ConfirmAlert .modal-content {
  border-radius: 15px; }
  .ConfirmAlert .modal-content .modal-header {
    border-radius: 15px 15px 0px 0px;
    flex-direction: column; }
    .ConfirmAlert .modal-content .modal-header.deleteHeader {
      background-color: #ff5252;
      color: #fff; }
      .ConfirmAlert .modal-content .modal-header.deleteHeader .modal-title {
        font-weight: normal; }
      .ConfirmAlert .modal-content .modal-header.deleteHeader .secondTitle {
        color: #000;
        font-weight: bold; }
    .ConfirmAlert .modal-content .modal-header p {
      margin: 0px; }
  .ConfirmAlert .modal-content .buttonBar {
    margin-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 10px; }
    .ConfirmAlert .modal-content .buttonBar .col-3 {
      padding: 0px 5px; }
      .ConfirmAlert .modal-content .buttonBar .col-3 button {
        font-weight: bold;
        font-size: 15px; }

#matchList {
  overflow-y: hidden; }
  #matchList .card {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    background-color: #f0f0f0;
    border-radius: 15px; }
    #matchList .card > .badge {
      position: absolute;
      top: -10px;
      right: 0px;
      width: 25px; }
    #matchList .card:last-child {
      margin-bottom: 0px; }
    #matchList .card.selectedMatchCard .card-header {
      background-image: -webkit-gradient(linear, left top, right bottom, from(#e8a7e3), to(#d1b477));
      background-image: linear-gradient(to bottom right, #e8a7e3, #d1b477);
      background-size: 300% 120%;
      background-position: 0% 0%;
      -webkit-animation-name: blinker;
              animation-name: blinker;
      -webkit-animation-duration: 4s;
              animation-duration: 4s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite; }
    #matchList .card.deleted, #matchList .card.submitted {
      -webkit-animation: disappear 0.5s forwards ease-out;
              animation: disappear 0.5s forwards ease-out; }
      #matchList .card.deleted .card-header,
      #matchList .card.deleted .card-body, #matchList .card.submitted .card-header,
      #matchList .card.submitted .card-body {
        -webkit-animation: disappearInner 0.5s forwards ease-out;
                animation: disappearInner 0.5s forwards ease-out; }
    #matchList .card.deleted {
      background-color: #ff7d7d;
      -webkit-transition: background-color 0.5s linear;
      transition: background-color 0.5s linear; }
    #matchList .card.submitted {
      background-color: #7dff7d;
      -webkit-transition: background-color 0.5s linear;
      transition: background-color 0.5s linear; }
    #matchList .card .card-header {
      display: flex;
      flex-direction: row;
      font-weight: bold;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: inherit;
      border-radius: 15px 15px 0px 0px; }
      #matchList .card .card-header .circle {
        -webkit-transform: translate(-2.5px, 0px);
                transform: translate(-2.5px, 0px);
        margin-right: 9.5px !important; }
      #matchList .card .card-header svg {
        margin-right: 5px; }
        #matchList .card .card-header svg path {
          -webkit-transform: translate(-75px, 0px);
                  transform: translate(-75px, 0px); }
      #matchList .card .card-header p {
        display: inline-block;
        margin: 0px; }
        #matchList .card .card-header p.dateHeader {
          margin-left: auto !important; }
          #matchList .card .card-header p.dateHeader.playing {
            color: green; }
          #matchList .card .card-header p.dateHeader.playingended {
            color: red; }
    #matchList .card .card-body {
      padding: 0px; }
      #matchList .card .card-body.creatorTools {
        height: 100px; }
        #matchList .card .card-body.creatorTools .badge {
          height: 20px;
          padding-top: 5px;
          margin-left: 5px;
          width: 20px; }
        #matchList .card .card-body.creatorTools .media .media-body {
          padding: 0px; }
          #matchList .card .card-body.creatorTools .media .media-body:not(:last-child) {
            border-right: 1px solid; }
            #matchList .card .card-body.creatorTools .media .media-body:not(:last-child) p:last-child {
              border-radius: 0px 0px 0px 15px; }
          #matchList .card .card-body.creatorTools .media .media-body:last-child p:last-child {
            border-radius: 0px 0px 15px 0px; }
          #matchList .card .card-body.creatorTools .media .media-body p {
            background-color: #eaeef1;
            margin: 0px;
            padding: 6px 0px 6px 15px;
            cursor: pointer; }
            #matchList .card .card-body.creatorTools .media .media-body p.disabled {
              opacity: 0.3;
              cursor: not-allowed; }
            #matchList .card .card-body.creatorTools .media .media-body p:hover:not(.disabled) {
              text-decoration: underline; }
            #matchList .card .card-body.creatorTools .media .media-body p:last-child {
              padding-bottom: 5px; }
              #matchList .card .card-body.creatorTools .media .media-body p:last-child:nth-child(2) {
                border-top: 1px solid; }
            #matchList .card .card-body.creatorTools .media .media-body p:not(:last-child) {
              border-bottom: 1px solid; }
      #matchList .card .card-body .media {
        height: 100px; }
        #matchList .card .card-body .media svg {
          margin-right: 5px; }
        #matchList .card .card-body .media img {
          max-width: 16.7%;
          max-height: 90%; }
        #matchList .card .card-body .media .media-body {
          padding: 8px 0px 9px 15px;
          width: 80%; }
          #matchList .card .card-body .media .media-body .line {
            max-width: 90%; }
          #matchList .card .card-body .media .media-body p {
            width: 100%;
            margin-bottom: 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden; }
            #matchList .card .card-body .media .media-body p:last-child {
              margin-bottom: 0px; }

.mobileCreatorTools {
  height: unset;
  top: unset;
  bottom: 0; }
  .mobileCreatorTools .badge {
    height: 20px;
    padding-top: 5px;
    margin-left: 5px;
    width: 20px; }
  .mobileCreatorTools.modal.fade > .modal-dialog {
    -webkit-transform: unset;
    -webkit-transform: translate(0, 200px);
    transform: unset;
    transform: translate(0, 200px); }
  .mobileCreatorTools.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none; }
  .mobileCreatorTools .modal-dialog {
    margin: 20px 0px 0px 0px; }
    .mobileCreatorTools .modal-dialog .modal-content {
      border-radius: 15px 15px 0px 0px; }
      .mobileCreatorTools .modal-dialog .modal-content .slider {
        position: absolute;
        top: -10px;
        background-color: #eee;
        width: 35px;
        align-self: center;
        padding: 2px;
        border-radius: 5px; }
      .mobileCreatorTools .modal-dialog .modal-content .modal-body.iPhone:not(.w320h568):not(.w375h667):not(.w414h736) {
        padding-bottom: 44px; }
      .mobileCreatorTools .modal-dialog .modal-content .modal-body p.disabled {
        opacity: 0.3; }
      .mobileCreatorTools .modal-dialog .modal-content .modal-body p:last-child {
        margin: 0; }
      .mobileCreatorTools .modal-dialog .modal-content .modal-body p svg {
        margin-right: 10px; }

@-webkit-keyframes blinker {
  0% {
    background-position: 0% 0%; }
  50% {
    background-position: 100% 100%; }
  100% {
    background-position: 0% 0%; } }

@keyframes blinker {
  0% {
    background-position: 0% 0%; }
  50% {
    background-position: 100% 100%; }
  100% {
    background-position: 0% 0%; } }

@-webkit-keyframes disappear {
  0% {
    height: 138px;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    margin: inherit; }
  50% {
    margin: 7.5px 0px; }
  100% {
    -webkit-transform: scaleY(0.7);
            transform: scaleY(0.7);
    height: 0px;
    margin: 0px;
    border: none; } }

@keyframes disappear {
  0% {
    height: 138px;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    margin: inherit; }
  50% {
    margin: 7.5px 0px; }
  100% {
    -webkit-transform: scaleY(0.7);
            transform: scaleY(0.7);
    height: 0px;
    margin: 0px;
    border: none; } }

@-webkit-keyframes disappearInner {
  0% {
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1); }
  25% {
    -webkit-transform: scaleY(0.2);
            transform: scaleY(0.2);
    opacity: 0; }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    opacity: 0; } }

@keyframes disappearInner {
  0% {
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1); }
  25% {
    -webkit-transform: scaleY(0.2);
            transform: scaleY(0.2);
    opacity: 0; }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    opacity: 0; } }

.LandingPage {
  height: 100%;
  display: flex; }
  .LandingPage .matchQuery {
    display: flex;
    flex-direction: column;
    height: 100%;
    -webkit-transition: ease 0.25s all;
    transition: ease 0.25s all;
    padding-top: 15px; }
  .LandingPage form h2 {
    margin: 0px; }
  .LandingPage form input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none; }
  .LandingPage form .accordion > .form-row {
    margin-bottom: 14px; }
    .LandingPage form .accordion > .form-row button svg {
      color: black;
      opacity: 1; }
      .LandingPage form .accordion > .form-row button svg path {
        -webkit-transition: opacity 0.15s, -webkit-transform 0.3s;
        transition: opacity 0.15s, -webkit-transform 0.3s;
        transition: transform 0.3s, opacity 0.15s;
        transition: transform 0.3s, opacity 0.15s, -webkit-transform 0.3s; }
      .LandingPage form .accordion > .form-row button svg.cross :first-child {
        -webkit-transform: rotate(-45deg) translate(-300px, 230px) scaleX(1.3);
                transform: rotate(-45deg) translate(-300px, 230px) scaleX(1.3); }
      .LandingPage form .accordion > .form-row button svg.cross :nth-child(2) {
        opacity: 0 !important; }
      .LandingPage form .accordion > .form-row button svg.cross :last-child {
        -webkit-transform: rotate(45deg) translate(50px, -400px) scaleX(1.3);
                transform: rotate(45deg) translate(50px, -400px) scaleX(1.3); }
  .LandingPage #matchList {
    height: calc(100% - 15px - 56px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    min-height: 0px;
    margin-bottom: 15px; }
    .LandingPage #matchList.mobileMode {
      height: calc(100% - 96px);
      margin-bottom: 0px;
      padding-bottom: 15px; }

.headerBox > .row {
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 14px; }

.headerBox.browser .titleBox {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .headerBox.browser .titleBox h1, .headerBox.browser .titleBox p {
    display: inline-block;
    margin: 0px; }
  .headerBox.browser .titleBox h1 {
    padding-right: 20px; }

.headerBox.mobile .titleBox {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .headerBox.mobile .titleBox h1 {
    text-align: center; }

.headerBox [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px; }

.headerBox h1 {
  margin: 0px; }

.headerBox hr {
  margin-top: 10px;
  margin-bottom: 10px; }

.headerBox .row > [class*="col-"] > button {
  font-size: 11px !important;
  font-weight: normal !important;
  text-align: center;
  width: 100%;
  padding: 1px; }

.firstVisitPage {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  -webkit-overflow-scrolling: touch; }
  .firstVisitPage p {
    font-size: 1.2rem; }
    .firstVisitPage p svg {
      padding-right: 5px; }
  .firstVisitPage .prImages {
    justify-content: center; }
    .firstVisitPage .prImages .smartphone {
      position: relative;
      width: 30vw;
      height: 60vw;
      margin: 5px -45px 35px 5px;
      border: 1vw black solid;
      background: white;
      border-top-width: 4vw;
      border-bottom-width: 6vw;
      border-radius: 2vw;
      z-index: 2; }
      .firstVisitPage .prImages .smartphone:before {
        content: '';
        display: block;
        width: 10vw;
        height: 0.5vw;
        position: absolute;
        top: -2vw;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: #333;
        border-radius: 0.2vw; }
      .firstVisitPage .prImages .smartphone:after {
        content: '';
        display: block;
        width: 3.75vw;
        height: 3.75vw;
        position: absolute;
        left: 50%;
        bottom: -7vw;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: #222;
        border-radius: 50%; }
      .firstVisitPage .prImages .smartphone:nth-child(2) {
        margin: 30px 5px 10px;
        z-index: 1; }
      .firstVisitPage .prImages .smartphone img {
        height: 100%;
        width: 100%;
        z-index: 1; }
    .firstVisitPage .prImages .smartphoneDesktop {
      position: relative;
      width: 10vw;
      height: 20vw;
      margin: 50px -45px 35px 5px;
      border: 0.3vw black solid;
      background: white;
      border-top-width: 1.3vw;
      border-bottom-width: 2vw;
      border-radius: 0.7vw;
      z-index: 2; }
      .firstVisitPage .prImages .smartphoneDesktop:before {
        content: '';
        display: block;
        width: 3vw;
        height: 0.2vw;
        position: absolute;
        top: -0.7vw;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: #333;
        border-radius: 0.05vw; }
      .firstVisitPage .prImages .smartphoneDesktop:after {
        content: '';
        display: block;
        width: 1.5vw;
        height: 1.5vw;
        position: absolute;
        left: 50%;
        bottom: -2.5vw;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: #222;
        border-radius: 50%; }
      .firstVisitPage .prImages .smartphoneDesktop:nth-child(2) {
        margin: 30px 5px 10px;
        z-index: 1; }
      .firstVisitPage .prImages .smartphoneDesktop img {
        height: 100%;
        width: 100%;
        z-index: 1; }
    .firstVisitPage .prImages .desktop {
      position: relative;
      width: 48.25vw;
      height: 30vw;
      margin: 5px 0px 35px 5px;
      border: 0.4vw black solid;
      background: white;
      border-top-width: 1vw;
      border-bottom-width: 2vw;
      border-radius: 0.5vw;
      z-index: 1; }
      .firstVisitPage .prImages .desktop img {
        height: 100%;
        width: 100%;
        z-index: 1; }
  .firstVisitPage .buttons .col-12:not(:last-child) {
    margin-bottom: 10px; }

.createMatchPage {
  height: 100%;
  display: flex;
  background: #271933;
  border-radius: 25px 0px 0px 25px;
  color: #eee;
  box-shadow: 15px 15px 25px 25px rgba(1, 1, 1, 0.2); }
  .createMatchPage .createMatch {
    margin-top: 15px;
    margin-bottom: 15px;
    height: calc(100% - 30px);
    overflow: scroll;
    -webkit-overflow-scrolling: touch; }
    .createMatchPage .createMatch form > * {
      margin-bottom: 10px; }
    .createMatchPage .createMatch form p.explainer {
      font-style: italic;
      padding-left: 16px;
      font-size: 12px;
      color: #aaa; }
    .createMatchPage .createMatch form .invalid-form {
      border: 2px solid #ff0000; }
    .createMatchPage .createMatch form div.invalid-feedback {
      font-size: 14px;
      display: block; }
    .createMatchPage .createMatch form select.sportSelect {
      background-size: auto 80%;
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%; }
    .createMatchPage .createMatch form span.input-group-text {
      min-width: 95px; }
    .createMatchPage .createMatch form select {
      -webkit-appearance: none;
      -moz-appearance: none;
      text-transform: capitalize; }
    .createMatchPage .createMatch form input[type=datetime-local]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none; }

.requestPage {
  height: 100%;
  display: flex;
  background: #271933;
  border-radius: 25px 0px 0px 25px;
  color: #eee;
  box-shadow: 15px 15px 25px 25px rgba(1, 1, 1, 0.2); }
  .requestPage .container-fluid {
    margin: 15px 0px;
    overflow: hidden; }
    .requestPage .container-fluid .requestTabBar.notScrollable svg {
      color: #777; }
    .requestPage .container-fluid .requestTabBar.scrollable div:hover svg {
      color: #fff; }
    .requestPage .container-fluid .requestTabBar div {
      padding: 0px; }
    .requestPage .container-fluid .row {
      width: 100%;
      margin-left: 0px; }
      .requestPage .container-fluid .row .media {
        width: 100%; }
    .requestPage .container-fluid .requestContent {
      height: calc(100% - 35px - 20px - 38px);
      display: flex;
      flex-direction: column;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      padding-bottom: 30px; }
      .requestPage .container-fluid .requestContent div.Report {
        margin-top: 7px; }
    .requestPage .container-fluid .noResults {
      height: calc(100% - 20px - 38px);
      display: flex;
      flex-direction: column;
      text-align: center; }
    .requestPage .container-fluid hr {
      width: 100%;
      margin-top: 5px;
      border-color: #eee; }
    .requestPage .container-fluid button {
      width: 100%;
      margin-bottom: 10px; }

.resultPage {
  height: 100%;
  display: flex;
  background: #271933;
  border-radius: 25px 0px 0px 25px;
  color: #eee;
  box-shadow: 15px 15px 25px 25px rgba(1, 1, 1, 0.2); }
  .resultPage .acceptedPlayers {
    width: 100%;
    padding-bottom: 10px; }
    .resultPage .acceptedPlayers .teamHeaders {
      text-align: center; }
      .resultPage .acceptedPlayers .teamHeaders p {
        margin: 5px; }
        .resultPage .acceptedPlayers .teamHeaders p.teamOne {
          color: #00b8e6; }
        .resultPage .acceptedPlayers .teamHeaders p.teamTwo {
          color: #e60000; }
    .resultPage .acceptedPlayers > div.row:not(.teamHeaders) > div {
      height: 52px;
      width: 100%;
      padding: 0px;
      margin: 5px 0px; }
      .resultPage .acceptedPlayers > div.row:not(.teamHeaders) > div.teamSwapper {
        display: flex;
        font-size: 20px; }
        .resultPage .acceptedPlayers > div.row:not(.teamHeaders) > div.teamSwapper svg {
          width: 100%;
          color: #eee;
          opacity: 0.3;
          align-self: center;
          text-align: center;
          cursor: pointer; }
      .resultPage .acceptedPlayers > div.row:not(.teamHeaders) > div .media {
        border-radius: calc(1.078em / 2);
        background-image: -webkit-gradient(linear, left top, right bottom, from(#e8a7e3), to(#d1b477));
        background-image: linear-gradient(to bottom right, #e8a7e3, #d1b477);
        color: black;
        cursor: pointer; }
        .resultPage .acceptedPlayers > div.row:not(.teamHeaders) > div .media.teamOne {
          border: 3px solid #00b8e6; }
        .resultPage .acceptedPlayers > div.row:not(.teamHeaders) > div .media.teamTwo {
          border: 3px solid #e60000; }
        .resultPage .acceptedPlayers > div.row:not(.teamHeaders) > div .media .media-body {
          padding: 2px;
          max-width: calc(100% - 40px); }
          .resultPage .acceptedPlayers > div.row:not(.teamHeaders) > div .media .media-body p {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0px; }
    .resultPage .acceptedPlayers p.error {
      margin-top: 15px;
      margin-bottom: 0px; }
  .resultPage hr {
    border-color: #eee; }
  .resultPage .container-fluid {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    margin: 15px 0px; }
  .resultPage .row, .resultPage form {
    width: 100%;
    margin-left: 0px; }
  .resultPage .form-row .col {
    justify-content: center;
    display: flex; }
    .resultPage .form-row .col .form-check label {
      padding-left: 5px; }
  .resultPage div.textarea {
    width: 100%;
    height: 82px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.1rem;
    font-size: 1.063rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #e9ecef;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem; }
    .resultPage div.textarea p {
      width: 100%;
      align-self: center;
      text-align: center;
      margin: 0px; }
  .resultPage form button,
  .resultPage form input,
  .resultPage form textarea,
  .resultPage form label,
  .resultPage button {
    width: 100%;
    margin-bottom: 10px; }
  .resultPage .btn-group button {
    width: unset; }

.CreateMatchPage {
  height: 100%;
  display: flex; }
  .CreateMatchPage .createMatchContent {
    width: 100%;
    margin-top: 15px;
    -webkit-transition: ease 0.25s all;
    transition: ease 0.25s all; }
    .CreateMatchPage .createMatchContent .btn {
      margin-top: 7px; }
  .CreateMatchPage #matchList {
    height: calc(100% - 22px - 50px - 30px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    min-height: 0px; }
    .CreateMatchPage #matchList.mobileMode {
      height: calc(100% - 15px - 95px - 38px); }

.LeaderboardPage {
  height: 100%;
  display: flex; }
  .LeaderboardPage .leaderboardContent {
    height: calc(100% - 30px);
    width: 100%;
    margin-top: 15px; }
  .LeaderboardPage select {
    height: 43px;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-bottom: 10px; }
    @media (max-width: 767px) {
      .LeaderboardPage select {
        height: calc(1.5em + 1rem + 2px);
        margin-bottom: 10px; } }
    .LeaderboardPage select option:not(:first-child) {
      text-transform: capitalize; }
    .LeaderboardPage select.sportSelect {
      background-size: auto 80%;
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%; }
  .LeaderboardPage .tableContainer {
    height: calc(100% - 51px);
    padding-bottom: 40px;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    @media (max-width: 767px) {
      .LeaderboardPage .tableContainer {
        height: calc(100% - 51px - 34.69px); } }
    .LeaderboardPage .tableContainer table {
      margin-bottom: 0px;
      width: calc(100%) !important;
      white-space: nowrap; }
      .LeaderboardPage .tableContainer table th:not(:nth-child(2)) {
        text-align: center; }
      .LeaderboardPage .tableContainer table td {
        line-height: 30px;
        text-overflow: ellipsis; }
        .LeaderboardPage .tableContainer table td:first-child img {
          height: 28px;
          margin: 0 0 0px 0; }
        .LeaderboardPage .tableContainer table td:not(:nth-child(2)) {
          text-align: center; }
        .LeaderboardPage .tableContainer table td img {
          margin-right: 10px; }
  .LeaderboardPage .leaderboardError {
    display: flex;
    justify-content: center; }

.MyMatchesPage {
  height: 100%;
  display: flex; }
  .MyMatchesPage .createMatchContent {
    width: 100%;
    margin-top: 15px;
    -webkit-transition: ease 0.25s all;
    transition: ease 0.25s all; }
    .MyMatchesPage .createMatchContent .btn {
      margin-top: 7px; }
  .MyMatchesPage #matchList {
    height: calc(100% - 15px - 50px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    min-height: 0px; }
    .MyMatchesPage #matchList.mobileMode {
      height: calc(100% - 95px);
      padding-bottom: 15px; }

.ImageUpload .profilePicCard {
  border: none;
  background-color: rgba(0, 0, 0, 0); }
  .ImageUpload .profilePicCard img.rounded-circle {
    border: 2px solid black; }
  .ImageUpload .profilePicCard .card-img-overlay {
    padding: 0px;
    opacity: 0;
    border-radius: 100%;
    color: #eee;
    cursor: pointer; }
    .ImageUpload .profilePicCard .card-img-overlay:hover {
      opacity: 100;
      background: rgba(1, 1, 1, 0.2); }

.dropzoneCard {
  cursor: context-menu; }

.ReactCrop__image {
  cursor: default; }

.passwordUpdate {
  z-index: 2;
  height: 100%;
  display: flex;
  padding-top: 15px;
  background: #271933;
  border-radius: 25px 0px 0px 25px;
  color: #eee;
  box-shadow: 15px 15px 25px 25px rgba(1, 1, 1, 0.2); }
  .passwordUpdate hr {
    margin-top: 5px;
    width: 100%;
    border-color: #eee; }
  .passwordUpdate .row {
    width: 100%;
    margin-left: 0px; }
  .passwordUpdate form {
    width: 100%;
    margin-bottom: 10px;
    text-align: center; }
    .passwordUpdate form input[name=newPasswordConfirm] {
      margin-bottom: 10px; }
    .passwordUpdate form input[type=passwordUnmasked] {
      font-style: italic; }
    .passwordUpdate form button {
      width: 100%; }
  .passwordUpdate .passwordMasking {
    position: absolute;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1.1rem;
    align-items: flex-end;
    font-size: 1.063rem; }
    .passwordUpdate .passwordMasking svg {
      color: #343a40;
      display: block;
      pointer-events: visible;
      height: calc(100%); }

.sportSelection {
  z-index: 2;
  height: 100%;
  display: flex;
  padding-top: 15px;
  background: #271933;
  border-radius: 25px 0px 0px 25px;
  color: #eee;
  box-shadow: 15px 15px 25px 25px rgba(1, 1, 1, 0.2); }
  .sportSelection hr {
    margin-top: 5px;
    width: 100%;
    border-color: #eee; }
  .sportSelection h1 {
    width: 100%; }
  .sportSelection .container-fluid {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 15px; }
  .sportSelection .row {
    width: 100%;
    margin-left: 0px; }
  .sportSelection button {
    width: 100%;
    margin-bottom: 10px; }
    .sportSelection button.sportSelector {
      width: calc(50% - 5px);
      display: flex;
      text-transform: capitalize;
      vertical-align: center;
      padding-right: 0px; }
      @media (min-width: 768px) and (max-width: 1200px) {
        .sportSelection button.sportSelector {
          width: calc(100%); } }
      .sportSelection button.sportSelector:nth-child(odd) {
        margin-left: auto; }
      .sportSelection button.sportSelector:nth-child(even) {
        margin-right: auto; }
      .sportSelection button.sportSelector p {
        font-size: 14px;
        margin-top: auto;
        margin: 10px 0px; }
      .sportSelection button.sportSelector img {
        margin-left: auto !important;
        height: 40px; }

.editableInfoShell .editableInfo {
  display: inline-block; }
  .editableInfoShell .editableInfo svg {
    margin-left: 10px;
    cursor: pointer; }
    .editableInfoShell .editableInfo svg.fa-pencil-alt {
      display: none; }
    .editableInfoShell .editableInfo svg:not(.fa-pencil-alt) {
      font-size: 17px;
      margin-bottom: -3px; }
    .editableInfoShell .editableInfo svg.fa-check {
      color: #23c01e; }
    .editableInfoShell .editableInfo svg.fa-times {
      color: #d31919; }
  .editableInfoShell .editableInfo:hover svg.fa-pencil-alt {
    display: inline-block; }
  .editableInfoShell .editableInfo.mobile svg.fa-pencil-alt {
    opacity: 0.2;
    display: inline-block; }
  .editableInfoShell .editableInfo input,
  .editableInfoShell .editableInfo select {
    display: inline-block;
    width: auto;
    height: 20px;
    font-size: 12px;
    padding: 0px; }
  .editableInfoShell .editableInfo input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none; }
  .editableInfoShell .editableInfo select {
    height: 19px; }

.editableInfoShell i.secret {
  margin-right: 10px; }

.AccountPage {
  height: 100%;
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .AccountPage ::-webkit-scrollbar {
    display: none; }
  .AccountPage .profileContent {
    margin-top: 15px;
    height: calc(100% - 30px);
    -webkit-transition: ease 0.25s all;
    transition: ease 0.25s all; }
    .AccountPage .profileContent.mobileMode {
      margin: 0px;
      padding: 15px;
      height: 100%; }
    .AccountPage .profileContent .shader {
      position: absolute;
      left: 0;
      z-index: 1;
      width: 30px;
      height: 180px;
      background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, white), to(rgba(255, 255, 255, 0)));
      background-image: linear-gradient(to right, white 50%, rgba(255, 255, 255, 0)); }
      .AccountPage .profileContent .shader.inverse {
        width: 15px;
        left: calc(100% - 20px - 15px);
        background-image: -webkit-gradient(linear, right top, left top, color-stop(25%, white), to(rgba(255, 255, 255, 0)));
        background-image: linear-gradient(to left, white 25%, rgba(255, 255, 255, 0)); }
    .AccountPage .profileContent .rankings {
      width: calc(100% + 10px);
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      margin: 0px -15px;
      padding: 0px 15px; }
      .AccountPage .profileContent .rankings .rankingCard {
        height: 175px;
        width: 140px;
        flex: none;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0px;
        margin-right: 20px; }
        .AccountPage .profileContent .rankings .rankingCard:last-child {
          margin-right: -10px; }
        .AccountPage .profileContent .rankings .rankingCard.gold {
          background-image: -webkit-gradient(linear, left top, right bottom, from(#ad8425), color-stop(35%, #f7ef88), color-stop(75%, #d2ad46), color-stop(85%, #ecd865), to(#ad8425));
          background-image: linear-gradient(to bottom right, #ad8425, #f7ef88 35%, #d2ad46 75%, #ecd865 85%, #ad8425); }
        .AccountPage .profileContent .rankings .rankingCard.silver {
          background-image: -webkit-gradient(linear, left top, right bottom, from(#7c7c79), color-stop(35%, #e1e1e0), color-stop(75%, #979795), color-stop(85%, #c5c5c4), to(#7c7c79));
          background-image: linear-gradient(to bottom right, #7c7c79, #e1e1e0 35%, #979795 75%, #c5c5c4 85%, #7c7c79); }
        .AccountPage .profileContent .rankings .rankingCard.bronze {
          background-image: -webkit-gradient(linear, left top, right bottom, from(#a35719), color-stop(35%, #da792b), color-stop(75%, #955018), color-stop(85%, #e18f41), to(#a35719));
          background-image: linear-gradient(to bottom right, #a35719, #da792b 35%, #955018 75%, #e18f41 85%, #a35719); }
        .AccountPage .profileContent .rankings .rankingCard object {
          height: 140px;
          width: 100%;
          padding: 0px 17.5px;
          align-self: center; }
        .AccountPage .profileContent .rankings .rankingCard p {
          text-transform: capitalize;
          font-weight: bold;
          margin: 7px 0px; }
          .AccountPage .profileContent .rankings .rankingCard p.noRank {
            text-transform: none;
            font-weight: lighter;
            font-style: italic; }
    .AccountPage .profileContent .profileTools {
      padding: 0px;
      margin: 10px 0px; }
      .AccountPage .profileContent .profileTools.pad {
        margin-top: 0px; }
      .AccountPage .profileContent .profileTools button {
        min-width: 200px;
        margin-right: 20px; }
  .AccountPage .reportHeader {
    margin-right: 10px; }
  .AccountPage .profileContentInner {
    height: calc(100% - 5px - 33px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    min-height: 0px; }
    .AccountPage .profileContentInner.mobileMode {
      height: calc(100%  - 45px);
      padding-bottom: 5px; }

.HelpPage {
  height: 100%;
  display: flex; }
  .HelpPage .teamOne {
    color: #00b8e6; }
  .HelpPage .teamTwo {
    color: #e60000; }
  .HelpPage .helpContent {
    margin-top: 15px;
    margin-bottom: 15px;
    height: calc(100% - 30px);
    overflow: scroll;
    -webkit-overflow-scrolling: touch; }
    .HelpPage .helpContent.mobileMode {
      margin: 0px;
      padding: 15px;
      height: 100%; }
    .HelpPage .helpContent .card {
      margin-bottom: 10px;
      overflow: visible; }
      .HelpPage .helpContent .card img {
        max-width: 100%;
        margin-bottom: 10px; }
      .HelpPage .helpContent .card:last-child {
        margin-bottom: 0px;
        overflow: hidden; }
        .HelpPage .helpContent .card:last-child .card-body {
          border-bottom: none; }
      .HelpPage .helpContent .card .card-body {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
        .HelpPage .helpContent .card .card-body p:last-child {
          margin: 0px; }

.privacyPolicy {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0px 15px;
  height: calc(100% - 30px);
  overflow: scroll;
  -webkit-overflow-scrolling: touch; }
  .privacyPolicy.mobileMode {
    margin: 0px;
    padding: 15px;
    height: 100%; }

