@import './src/constants/styling.scss';

.sportSelection {
  z-index: 2;
  height: 100%;
  display: flex;
  padding-top: 15px;
  background: $submenuBg;
  border-radius: $roundedDivs;
  color: $textOnDark;
  box-shadow: 15px 15px 25px 25px $shadow;

  hr {
    margin-top: 5px;
    width: 100%;
    border-color: $textOnDark;
  }

  h1 {
    width: 100%;
  }

  .container-fluid {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 15px;
  }

  .row {
    width: 100%;
    margin-left: 0px;
  }

  button {
    width: 100%;
    margin-bottom: 10px;

    &.sportSelector {
      width: calc(50% - 5px);
      @media (min-width: 768px) and (max-width: 1200px) {
        width: calc(100%);
      }
      display: flex;
      text-transform: capitalize;
      vertical-align: center;
      padding-right: 0px;

      &:nth-child(odd) { margin-left: auto; }
      &:nth-child(even) { margin-right: auto; }

      p {
        font-size: 14px;
        margin-top: auto;
        margin: 10px 0px;
      }

      img {
        margin-left: auto !important;
        height: 40px;
      }
    }
  }
}
