@import './src/constants/styling.scss';

.ImageUpload {

  .profilePicCard {
    border: none;
    background-color: rgba(0,0,0,0);

    img.rounded-circle {
      border: 2px solid black;
    }

    .card-img-overlay {
      padding: 0px;
      opacity: 0;
      border-radius: 100%;
      color: $textOnDark;
      cursor: pointer;

      &:hover {
        opacity: 100;
        background: $overlay;
      }
    }
  }
}

.dropzoneCard {
  cursor: context-menu;
}

.ReactCrop__image {
  cursor: default;
}