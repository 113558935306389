@import './src/constants/styling.scss';

.navBrowser {
  height: 100%;
  max-width: 250px;

  #navigation {
    background-image: $browserNavbarBg;
    height: 100%;
    flex-direction: column;

    .navbar-brand {
      margin: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column;

      img {
        align-self: center;
      }
    }
  
    .navbar-nav {
      flex-direction: column;
      
      a {
        display: block;
        font-size: 16px;
  
        svg {
          padding-top: 8px;
        }
  
        &.notActive {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}

