@import './src/constants/styling.scss';

.HelpPage {
  height: 100%;
  display: flex;

  .teamOne {
    color: $teamOne;
  }

  .teamTwo {
    color: $teamTwo;
  }

  .helpContent {
    margin-top: 15px;
    margin-bottom: 15px;
    height: calc(100% - 30px);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    &.mobileMode {
      margin: 0px;
      padding: 15px;
      height: 100%;
    }

    .card {
      margin-bottom: 10px;
      overflow: visible;

      img {
        max-width: 100%;
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0px;
        overflow: hidden;

        .card-body {
          border-bottom: none;
        }
      }

      .card-body {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);

        p:last-child {
          margin: 0px;
        }
      }
    }
  }
}