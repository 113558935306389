@import './src/constants/styling.scss';

.LeaderboardPage {
  height: 100%;
  display: flex;

  .leaderboardContent {
    height: calc(100% - 30px);
    width: 100%;
    margin-top: 15px;
  }

  select {
    height: 43px;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-bottom: 10px;
    
    @media (max-width: 767px) {
      height: calc(1.5em + 1rem + 2px);
      margin-bottom: 10px;
    }
    
    option:not(:first-child) {
      text-transform: capitalize;
    }

    &.sportSelect {
      background-size: auto 80%;
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%;
    }
  }

  .tableContainer {
    height: calc(100% - 51px);
    padding-bottom: 40px;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @media (max-width: 767px) { 
      height: calc(100% - 51px - 34.69px)
    }

    table {
      margin-bottom: 0px;
      width: calc(100%) !important;
      white-space: nowrap;

      th {
        &:not(:nth-child(2)) {
          text-align: center;
        }
      }

      td {
        line-height: 30px;
        text-overflow: ellipsis;

        &:first-child {
          img {
            height: 28px;
            margin: 0 0 0px 0;
          }
        }

        &:not(:nth-child(2)) {
          text-align: center;
        }

        img {
          margin-right: 10px;
        }
      }
    }
  }

  .leaderboardError{
    display: flex;
    justify-content: center;
  }
}