@import './src/constants/styling.scss';

#matchList {
  overflow-y: hidden;

  .card {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    background-color: hsl(0, 0%, 94%);
    border-radius: $roundedCards;

    > .badge {
      position: absolute;
      top: -10px;
      right: 0px;
      width: 25px;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    &.selectedMatchCard .card-header {
      background-image: $browserNavbarBg;
      background-size: 300% 120%;
      background-position: 0% 0%;
      animation-name: blinker;
      animation-duration: 4s;
      animation-iteration-count: infinite;
    }

    &.deleted,
    &.submitted {
      animation: disappear 0.5s forwards ease-out;

      .card-header,
      .card-body {
        animation: disappearInner 0.5s forwards ease-out;
      }
    }

    &.deleted {
      background-color: rgb(255, 125, 125);
      transition: background-color 0.5s linear;
    }

    &.submitted {
      background-color: rgb(125, 255, 125);
      transition: background-color 0.5s linear;
    }

    .card-header {
      display: flex;
      flex-direction: row;
      font-weight: bold;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: inherit;
      border-radius: $roundedCardHeader;

      .circle {
        transform: translate(-2.5px, 0px);
        margin-right: 9.5px !important;
      }

      svg {
        margin-right: 5px;

        path {
          transform: translate(-75px, 0px);
        }
      }

      p {
        display: inline-block;
        margin: 0px;

        &.dateHeader {
          margin-left: auto !important;

          &.playing { color: green; }
          &.playingended { color: red; }
        }
      }
    }

    .card-body {
      padding: 0px;

      &.creatorTools {
        height: 100px;

        .badge {
          height: 20px;
          padding-top: 5px;
          margin-left: 5px;
          width: 20px;
        }

        .media {
          .media-body {
            padding: 0px;

            &:not(:last-child) {
              border-right: 1px solid;

              p:last-child {
                border-radius: 0px 0px 0px $roundedCardCorner;
              }
            }

            &:last-child {
              p:last-child {
                border-radius: 0px 0px $roundedCardCorner 0px;
              }
            }

            p {
              background-color: $skeletonLoadingShimmer;
              margin: 0px;
              padding: 6px 0px 6px 15px;
              cursor: pointer;

              &.disabled {
                opacity: 0.3;
                cursor: not-allowed;
              }

              &:hover:not(.disabled) {
                text-decoration: underline;
              }

              &:last-child {
                padding-bottom: 5px;
                &:nth-child(2) {
                  border-top: 1px solid;
                }
              }

              &:not(:last-child) {
                border-bottom: 1px solid;
              }
            }
          }
        }
      }

      .media {
        height: 100px;

        svg {
          margin-right: 5px;
        }

        img {
          max-width: 16.7%;
          max-height: 90%;
        }
      
        .media-body {
          padding: 8px 0px 9px 15px;
          width: 80%;

          .line {
            max-width: 90%;
          }

          p {
            width: 100%;
            margin-bottom: 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}

.mobileCreatorTools {
  height: unset;
  top: unset;
  bottom: 0;

  .badge {
    height: 20px;
    padding-top: 5px;
    margin-left: 5px;
    width: 20px;
  }

  &.modal.fade > .modal-dialog {
    -webkit-transform: unset; -webkit-transform: translate(0, 200px);
    transform: unset; transform: translate(0, 200px);
  }

  &.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
  }

  .modal-dialog {
    margin: 20px 0px 0px 0px;

    .modal-content {
      border-radius: $roundedCardHeader;
      .slider {
        position: absolute;
        top: -10px;
        background-color: $textOnDark;
        width: 35px;
        align-self: center;
        padding: 2px;
        border-radius: 5px;
      }

      .modal-body {
        &.iPhone:not(.w320h568):not(.w375h667):not(.w414h736) {
          padding-bottom: $paddingNavbarSlider;
        }

        p {
          &.disabled {
            opacity: 0.3;
          }

          &:last-child {
            margin: 0;
          }

          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@keyframes blinker {
  0%   {background-position: 0% 0%}
  50%  {background-position: 100% 100%}
  100% {background-position: 0% 0%}
}


@keyframes disappear {
  0% {
    height: 138px;
    transform: scaleY(1);
    margin: inherit;
  }
  50% {
    margin: 7.5px 0px;
  }
  100% {
    transform: scaleY(0.7);
    height: 0px;
    margin: 0px;
    border: none;
  }
}

@keyframes disappearInner {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }
  25% {
    transform: scaleY(0.2);
    opacity: 0;
  }
  100% {
    transform: scaleY(0);
    opacity: 0;
  }
}