@import './src/constants/styling.scss';

.matchPage {
  height: 100%;
  display: flex;
  background: $submenuBg;
  border-radius: $roundedDivs;
  color: $textOnDark;
  box-shadow: 15px 15px 25px 25px $shadow;

  .media {
    width: 100%;

    p {
      margin-bottom: 0px;
    }
  }

  .acceptedPlayers {
    width: 100%;

    > div {
      padding: 0px;
      margin: 5px 0px;

      .media {

        p {
          text-overflow: ellipsis;
          margin: 0px;
        }
      }
    }
  }

  hr {
    border-color: $textOnDark;
  }

  .container-fluid {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    margin: 15px 0px;
  }

  .buttonRow {
    .col-6 {
      padding: 0px 5px 0px 0px;
      &:last-child{
        padding: 0px 0px 0px 5px;
      }
    }
  }

  .row, form {
    width: 100%;
    margin-left: 0px;
  }

  div.textarea {
    width: 100%;
    height: 82px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.1rem;
    font-size: 1.063rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #e9ecef;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;

    p {
      width: 100%;
      align-self: center;
      text-align: center;
      margin: 0px;
    }
  }

  form button,
  form input,
  form textarea,
  form label,
  button {
    width: 100%;
    margin-bottom: 10px;
  }
}
