@import './src/constants/styling.scss';

.requestPage {
  height: 100%;
  display: flex;
  background: $submenuBg;
  border-radius: $roundedDivs;
  color: $textOnDark;
  box-shadow: 15px 15px 25px 25px $shadow;

  .container-fluid {
    margin: 15px 0px;
    overflow: hidden;

    .requestTabBar {
      &.notScrollable {
        svg {
          color: #777;
        }
      }

      &.scrollable {
        div:hover {
          svg {
            color: #fff;
          }
        }
      }

      div {
        padding: 0px;
      }
    }

    .row {
      width: 100%;
      margin-left: 0px;

      .media {
        width: 100%;
      }
    }

    .requestContent {
      // Minus for header, hr, button
      height: calc(100% - 35px - 20px - 38px);
      display: flex;
      flex-direction: column;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      padding-bottom: 30px;

      div.Report {
        margin-top: 7px;
      }
    }

    .noResults {
      // Minus for hr, button
      height: calc(100% - 20px - 38px);
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    hr {
      width: 100%;
      margin-top: 5px;
      border-color: $textOnDark;
    }

    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
