@import './src/constants/styling.scss';

.MyMatchesPage {
  height: 100%;
  display: flex;

  .createMatchContent {
    width: 100%;
    margin-top: 15px;
    transition: $slideIn;

    .btn {
      margin-top: 7px;
    }
  }

  #matchList {
    //Full height - margins - header
    height: calc(100% - 15px - 50px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    min-height: 0px;

    &.mobileMode {
      //Full height - margins - header
      height: calc(100% - 95px);
      padding-bottom: 15px;
    }
  }
}