@import './src/constants/styling.scss';
@import url('https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap');

html, body, #root, 
#root>div.container-fluid, 
#root>div.container-fluid>div#lastElementPreNavigation {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: contain;
  font-family: 'Raleway';
  font-size: 14px;
  flex-direction: row;
  background-image: $browserNavbarBgAlignRight;

  h1, h2, h3, h4, h5 {
    font-weight: normal
  }

  h1 {
    font-size: 24px;
  }

  &.whiteBackground {
    background-image: none;
    background-color: #fff;
  }

  .content {
    padding: 0px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    
    @media (max-width: 767px) {
      .browserSidebar {
        width: 100%;
        height: 100%;
        position: absolute !important;
        overflow: hidden;
        top: 0;
        left: 0;
        z-index: 10;
      }
    }

    .mobileOverlay {
      width: 100%;
      height: calc(100% - #{$mobileNavbarHeight} - #{$paddingNavbarStandard} - 1px);
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      z-index: 10;
      border-radius: $roundedMobileDivs;

      > .container-fluid {
        margin: 3px;
        padding: 12px;
      }

      &.iPhone:not(.w320h568):not(.w375h667):not(.w414h736) {
        height: calc(100% - #{$mobileNavbarHeight} - #{$paddingNavbarSlider} - 1px);
      }
    }

    &.mobileUI {
      height: calc(100% - #{$mobileNavbarHeight} - #{$paddingNavbarStandard});
    }

    &.iPhone:not(.w320h568):not(.w375h667):not(.w414h736) {
      height: calc(100% - #{$mobileNavbarHeight} - #{$paddingNavbarSlider});
    }
  }

  .navBrowser, 
  .contentCol {
    padding: 0px;
  }

  .contentCol {
    background-color: #fff;
    box-shadow: 15px 15px 25px 25px $shadow;
    border-radius: $roundedDivs;
  }

  p.error {
    color: #ff0000;
  }

  p.success {
    color: #17e217;
  }

  p.desc {
    text-align: center;
    font-style: italic;
  }

  button {
    font-weight: bold;
    font-size: 15px;
  }

  .skeletonLoading {
    background-color: $skeletonLoading;
    background-image: $skeletonLoadingGradient;
    animation: skeletonShimmer 4s infinite linear;
    background-attachment: fixed;
    background-size: 200%;
    margin: 0;

    &.line {
      display: inline-block;
      border-radius: 4px;
    }
  
    &.circle {
      display: inline-block;
      border-radius: 50%;
      margin-right: 7.5px;
    }
  }

  svg.skeletonLoading {
    background-image: none;
    background-color: transparent;
  }

  .paginationTools {
    text-align: center;

    p {
      margin-bottom: 0;
    
      &.paginationLink {
        display: inline;
        text-shadow: -2px -2px white, -2px 2px white, 2px -2px white, 2px 2px white;
        padding-bottom: 1.5px;

        &:hover {
          background-size: 1px 1em;
          box-shadow: inset 0 -0.175em white, inset 0 -0.2em #000;
        }
      }
    }
  }

  p {
    .line {
      height: 14px;
    }

    .circle {
      width: 14px;
      height: 14px;
    }
  }

  * {
    font-family: 'Raleway';
  }
}

@keyframes skeletonShimmer {
  to {
    background-position-x: -200%;
  }
}