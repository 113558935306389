@import './src/constants/styling.scss';

.privacyPolicy {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0px 15px;
  height: calc(100% - 30px);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  &.mobileMode {
    margin: 0px;
    padding: 15px;
    height: 100%;
  }
}