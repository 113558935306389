// Conditional padding
$mobileNavbarHeight: calc(39.781px + 7px);
$paddingNavbarStandard: 7px;
$paddingNavbarSlider: 44px;

// Colors
$browserNavbarStart: #e8a7e3;
$browserNavbarEnd: #d1b477;

$browserNavbarBg: linear-gradient(to bottom right, $browserNavbarStart, $browserNavbarEnd);
$browserNavbarBgAlignRight: linear-gradient(to bottom, mix($browserNavbarStart, $browserNavbarEnd), $browserNavbarEnd);
$textOnDark: #eee;
$submenuBg: rgb(39, 25, 51);
$shadow: rgba(1, 1, 1, 0.2);
$overlay: rgba(1, 1, 1, 0.2);

$skeletonLoading: #ced4da;
$skeletonLoadingShimmer: #eaeef1;
$skeletonLoadingGradient: linear-gradient(280deg, $skeletonLoading 20%, $skeletonLoadingShimmer 50%, $skeletonLoading 80%);

$teamOne: #00b8e6;
$teamTwo: #e60000;

// Edges
$roundedDivs: 25px 0px 0px 25px;
$roundedMobileDivs: 15px 0px 0px 15px;
$roundedCards: 15px;
$roundedCardCorner: 15px;
$roundedCardHeader: 15px 15px 0px 0px;

// Transitions
$slideIn: ease 0.25s all;