.editableInfoShell{
  .editableInfo {
    display: inline-block;

    svg {
      margin-left: 10px;
      cursor: pointer;

      &.fa-pencil-alt {
        display: none;
      }

      &:not(.fa-pencil-alt) {
        font-size: 17px;
        margin-bottom: -3px;
      }

      &.fa-check {
        color: #23c01e;
      }

      &.fa-times {
        color: #d31919;
      }
    }

    &:hover svg.fa-pencil-alt {
      display: inline-block;
    }

    &.mobile svg.fa-pencil-alt {
      opacity: 0.2;
      display: inline-block;
    }

    input,
    select {
      display: inline-block;
      width: auto;
      height: 20px;
      font-size: 12px;
      padding: 0px;
    }

    input[type=date]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none;
    }

    select {
      height: 19px;
    }
  }

  i.secret {
    margin-right: 10px;
  }
}