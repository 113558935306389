@import './src/constants/styling.scss';

.createMatchPage {
  height: 100%;
  display: flex;
  background: $submenuBg;
  border-radius: $roundedDivs;
  color: $textOnDark;
  box-shadow: 15px 15px 25px 25px $shadow;

  .createMatch {
    margin-top: 15px;
    margin-bottom: 15px;
    height: calc(100% - 30px);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    form {
      > * {
        margin-bottom: 10px;
      }

      p.explainer {
        font-style: italic;
        padding-left: 16px;
        font-size: 12px;
        color: #aaa;
      }

      .invalid-form {
        border: 2px solid #ff0000;
      }

      div.invalid-feedback {
        font-size: 14px;
        display: block;
      }

      select.sportSelect {
        background-size: auto 80%;
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 50%;
      }

      span.input-group-text {
        min-width: 95px;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-transform: capitalize;
      }

      input[type=datetime-local]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        display: none;
      }
    }
  }
}