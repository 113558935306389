.firstVisitPage {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  -webkit-overflow-scrolling: touch;

  p {
    font-size: 1.2rem;

    svg {
      padding-right: 5px;
    }
  }

  .prImages {
    justify-content: center;
    
    .smartphone {
      position: relative;
      width: 30vw;
      height: 60vw;
      margin: 5px -45px 35px 5px;
      border: 1vw black solid;
      background: white;
      border-top-width: 4vw;
      border-bottom-width: 6vw;
      border-radius: 2vw;
      z-index: 2;

      &:before {
        content: '';
        display: block;
        width: 10vw;
        height: 0.5vw;
        position: absolute;
        top: -2vw;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 0.2vw;
      }

      &:after {
        content: '';
        display: block;
        width: 3.75vw;
        height: 3.75vw;
        position: absolute;
        left: 50%;
        bottom: -7vw;
        transform: translate(-50%, -50%);
        background: #222;
        border-radius: 50%;
      }

      &:nth-child(2) {
        margin: 30px 5px 10px;
        z-index: 1;
      }

      img {
        height: 100%;
        width: 100%;
        z-index: 1;
      }
    }

    .smartphoneDesktop {
      position: relative;
      width: 10vw;
      height: 20vw;
      margin: 50px -45px 35px 5px;
      border: 0.3vw black solid;
      background: white;
      border-top-width: 1.3vw;
      border-bottom-width: 2vw;
      border-radius: 0.7vw;
      z-index: 2;

      &:before {
        content: '';
        display: block;
        width: 3vw;
        height: 0.2vw;
        position: absolute;
        top: -0.7vw;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #333;
        border-radius: 0.05vw;
      }

      &:after {
        content: '';
        display: block;
        width: 1.5vw;
        height: 1.5vw;
        position: absolute;
        left: 50%;
        bottom: -2.5vw;
        transform: translate(-50%, -50%);
        background: #222;
        border-radius: 50%;
      }

      &:nth-child(2) {
        margin: 30px 5px 10px;
        z-index: 1;
      }

      img {
        height: 100%;
        width: 100%;
        z-index: 1;
      }
    }

    .desktop {
      position: relative;
      width: 48.25vw;
      height: 30vw;
      margin: 5px 0px 35px 5px;
      border: 0.4vw black solid;
      background: white;
      border-top-width: 1vw;
      border-bottom-width: 2vw;
      border-radius: 0.5vw;
      z-index: 1;

      img {
        height: 100%;
        width: 100%;
        z-index: 1;
      }
    }
  }

  .buttons {
    .col-12:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}