@import './src/constants/styling.scss';

.LandingPage {
  height: 100%;
  display: flex;

  .matchQuery {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: $slideIn;
    padding-top: 15px;
  }

  form {
    h2 {
      margin: 0px;
    }

    input[type=date]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none;
    }

    .accordion {
      > .form-row {
        margin-bottom: 14px;

        button svg {
          color: black;
          opacity: 1;

          path {
            transition: transform 0.3s, opacity 0.15s;
          }

          &.cross {
            :first-child { transform: rotate(-45deg) translate(-300px, 230px) scaleX(1.3) }
            :nth-child(2) { opacity: 0 !important }
            :last-child { transform: rotate(45deg) translate(50px, -400px) scaleX(1.3) }
          }
        }
      }
    }    
  }

  #matchList {
    //Full height - margins - form
    height: calc(100% - 15px - 56px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    min-height: 0px;
    margin-bottom: 15px;

    &.mobileMode {
      //Full height - margins - form
      height: calc(100% - 96px);
      margin-bottom: 0px;
      padding-bottom: 15px;
    }
  }
}