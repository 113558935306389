@import './src/constants/styling.scss';

.CreateMatchPage {
  height: 100%;
  display: flex;

  .createMatchContent {
    width: 100%;
    margin-top: 15px;
    transition: $slideIn;

    .btn {
      margin-top: 7px;
    }
  }

  #matchList {
    //Full height - margins - header - button
    height: calc(100% - 22px - 50px - 30px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    min-height: 0px;

    &.mobileMode {
      //Full height - margins - header - button
      height: calc(100% - 15px - 95px - 38px);
    }
  }
}