.LoginPage {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;

  .btn {
    width: 100%;

    &.btn-facebook {
      background-image: linear-gradient(150deg, #3b5998, #2286c0, #0980c5, #1d78c2, #3b5998);
    }
  }

  form button,
  form input,
  form label {
    margin-bottom: 10px;

    &[type=passwordUnmasked] {
      font-style: italic;
    }
  }

  .passwordMasking {
    position: absolute;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1.1rem;
    align-items: flex-end;
    font-size: 1.063rem;

    svg {
      display: block;
      pointer-events: visible;
      height: calc(100%);
    }
  }

  .loadingIndication {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
