.headerBox {
  

  > .row {
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: 14px;
  }

  &.browser {
    .titleBox {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1, p {
        display: inline-block;
        margin: 0px;
      }

      h1 {
        padding-right: 20px;
      }
    }
  }

  &.mobile {
    .titleBox {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        text-align: center;
      }
    }
  }

  & [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }

  h1 {
    margin: 0px;
  }

  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & .row > [class*="col-"] > button {
    font-size: 11px !important;
    font-weight: normal !important;
    text-align: center;
    width: 100%;
    padding: 1px;
  }
}