@import './src/constants/styling.scss';

.NavigationMobile {
  padding: 7px;

  &.iPhone:not(.w320h568):not(.w375h667):not(.w414h736) {
    padding-bottom: $paddingNavbarSlider;
  }

  .col-2 {
    text-align: center;
    padding: 0px;

    a {
      color: #333;
      white-space: nowrap;

      svg {
        align-self: center;
      }

      p {
        margin: 0px;
        font-size: 8px;
        padding-bottom: 0px;

        @media (max-width: 350px) {
          font-size: 7px;
          padding-bottom: 1px;
        }

        @media (min-width: 450px) {
          font-size: 9px;
          margin-bottom: -1px;
        }

        @media (min-width: 500px) {
          font-size: 10px;
          margin-bottom: -2px;
        }

        @media (min-width: 700px) {
          font-size: 11px;
          margin-bottom: -3px;
        }
      }

      &:visited {
        color: #333;
      }

      &:hover,
      &.active {
        color: mix($browserNavbarStart, $browserNavbarEnd);
        text-decoration: none;
      }

      &.notActive {
        color: #333;
      }
    }
  }

  @media (min-width: 768px) {
    .container {
        max-width: 800px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 1000px;
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px;
    }
  }
}