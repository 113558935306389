@import './src/constants/styling.scss';

.Report {
  p {
    width: 100%;
    align-self: center;
    text-align: center;
    margin: 0px;
  }

  form {
    select,
    textarea {
      margin-bottom: 10px;
    }
    button {
      font-weight: bold;
      font-size: 15px;
    }
  }
}